export const titleMap1 = {
  1: '月份',
  2: '行政区域',
  3: '使用单位',
  4: '维保单位',
  5: '使用场所',
  6: '故障类型',
  7: '电梯',
  8: '品牌',
  9: '设备生产商',
}
export const titleMap2 = {
  1: '行政区域',
  2: '使用单位',
  3: '维保单位',
}
export const titleMap3 = {
  1: '行政区域',
  2: '使用单位',
  3: '维保单位',
  4: '维保小组',
  5: '维保人员',
}
export const titleMap5 = {
  1: '维保单位',
  2: '维保小组',
}