import { render, staticRenderFns } from "./StatisticPage4.vue?vue&type=template&id=5a9d8e80&scoped=true&"
import script from "./StatisticPage4.vue?vue&type=script&lang=js&"
export * from "./StatisticPage4.vue?vue&type=script&lang=js&"
import style0 from "./StatisticPage4.vue?vue&type=style&index=0&id=5a9d8e80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a9d8e80",
  null
  
)

export default component.exports