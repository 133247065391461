<template>
  <page-container title="维修保养统计" :breadcrumb="breadcrumb">
    <div class="top-page">
      <a-menu :selected-keys="selectedKeys" :style="{ borderRight: 0 }" mode="horizontal">
        <template v-for="item in statisticMenus">
          <sub-menu v-if="item.children && item.children.length" :key="item.code" :menu-info="item" @click-menu-item="clickLeftMenu"></sub-menu>
          <a-menu-item v-else :key="item.code" @click-menu-item="clickLeftMenu(item)">{{item.text}}</a-menu-item>
        </template>
      </a-menu>
    </div>
    <div class="detail-page">
<!--      <div class="left-tabs">-->
<!--        <a-menu :selected-keys="selectedKeys" :style="{ borderRight: 0 }">-->
<!--          <template v-for="item in statisticMenus">-->
<!--            <sub-menu v-if="item.children && item.children.length" :key="item.code" :menu-info="item" @click-menu-item="clickLeftMenu"></sub-menu>-->
<!--            <a-menu-item v-else :key="item.code" @click-menu-item="clickLeftMenu(item)">{{item.text}}</a-menu-item>-->
<!--          </template>-->
<!--        </a-menu>-->
<!--      </div>-->
      <div class="page-content">
        <statistic-page1 v-if="interfaceName=='getMaintenanceStatistic'" ref="statisticpage1" :querytype="querytype"></statistic-page1>
        <statistic-page2 v-if="interfaceName=='getMaintenanceLiftStatistic'" ref="statisticpage1" :querytype="querytype"></statistic-page2>
        <statistic-page3 v-if="interfaceName=='getMaintenanceMainStatistic'" ref="statisticpage1" :querytype="querytype"></statistic-page3>
        <statistic-page4 v-if="interfaceName=='getMaintenanceKeepStatistic'" ref="statisticpage1" :querytype="querytype"></statistic-page4>
        <statistic-page5 v-if="interfaceName=='getUserStatistic'" ref="statisticpage1" :querytype="querytype"></statistic-page5>
      </div>
    </div>
  </page-container>
</template>
<script>
import { Menu } from 'ant-design-vue';
const SubMenu = {
  template: `
      <a-sub-menu :key="menuInfo.code" v-bind="$props" v-on="$listeners">
        <span slot="title">
          <a-icon v-if="menuInfo.menupic" :type="menuInfo.menupic" />
          <span>{{ menuInfo.text }}</span>
        </span>
        <template v-for="item in menuInfo.children">
          <sub-menu v-if="item.children && item.children.length" :key="item.code" :menu-info="item" @click-menu-item="clickMenuItem" />
          <a-menu-item v-else :key="item.code" @click="clickMenuItem(item)">
            <a-icon v-if="item.menupic" :type="item.menupic" />
            <span>{{ item.text }}</span>
          </a-menu-item>
        </template>
      </a-sub-menu>
    `,
  name: 'SubMenu',
  // must add isSubMenu: true
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    // Cannot overlap with properties within Menu.SubMenu.props
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    clickMenuItem(item) {
      this.$emit('click-menu-item', item);
    }
  }
};
import { statisticMenus, statisticMenusDefault } from '@/json/bigdata.js'
import StatisticPage1 from 'V/dsjfzjc/maintenanceStatistic/statistic/StatisticPage1'
import StatisticPage2 from 'V/dsjfzjc/maintenanceStatistic/statistic/StatisticPage2'
import StatisticPage3 from 'V/dsjfzjc/maintenanceStatistic/statistic/StatisticPage3'
import StatisticPage4 from 'V/dsjfzjc/maintenanceStatistic/statistic/StatisticPage4'
import StatisticPage5 from 'V/dsjfzjc/maintenanceStatistic/statistic/StatisticPage5'
import callCenter from "C/modals/callCenter";
export default {
  components: {
    StatisticPage1,
    StatisticPage2,
    StatisticPage3,
    StatisticPage4,
    StatisticPage5,
    SubMenu,
  },
  data() {
    return {
      statisticMenus,
      breadcrumb: [
        {
          name: '大数据辅助决策',
          path: ''
        },
        {
          name: '维保统计分析',
          path: ''
        },
        {
          name: '维修保养统计',
          path: ''
        },
      ],
      selectedKeys: [statisticMenusDefault.code],
      interfaceName: statisticMenusDefault.interfaceName,
      querytype: statisticMenusDefault.querytype,
    }
  },
  methods: {
    clickLeftMenu(menuItem) {
      if(menuItem && menuItem.interfaceName) {
        this.selectedKeys = [menuItem.code];
        this.interfaceName = menuItem.interfaceName;
        this.querytype = menuItem.querytype;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.detail-page {
  display: flex;
  padding: 10px;
  .left-tabs {
    flex-shrink: 0;
    width: 180px;
    min-height: calc(100vh - 120px);
    padding-top: 20px;
    background-color: white;
    border-radius: 2px;
  }
  .page-content {
    flex-grow: 1;
    margin-right: 15px;
    margin-left: 10px;
  }
}
.top-page{
  margin-top: 10px;
  height: 40px;
  //flex-grow: 1;
  margin-left: 20px;
  margin-right: 10px;
  margin-bottom: 6px;
  background-color: white;
}
</style>