<!--
 * @Author: zhaoxin
 * @Date: 2021-03-10 10:49:32
 * @Description: 维修情况统计
-->
<template>
  <div class="statistic-container">
    <div class="box-container sm-container">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="查询条件" prop="queryvalue">
              <a-input v-model.trim="queryParams.queryvalue" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item v-if="querytype==1" label="区域等级" prop="arealevel">
              <a-select v-model="arealevel">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="2">省</a-select-option>
                <a-select-option :value="4">市</a-select-option>
                <a-select-option :value="6">县</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="initTableData(true)">查询</a-button>
              <a-button class="after-btn" @click="exportData(true)">导出</a-button>
              <!-- <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button> -->
            </div>
          </a-form-model>
        </div>
        <!-- <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="设备类型" prop="terminalmodlenum">
              <a-select v-model="queryParams.terminalmodlenum">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in terminaltypes" :key="index" :value="item.value">{{item.text}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </div> -->
      </div>
    </div>
    <template>
      <div ref="echarts" class="echars-container"></div>
      <div class="box-container sm-container" style="margin-top: 10px;">
        <div class="box-container-inner">
          <a-table :columns="tableColumns" :data-source="tableData" row-key="keyname" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
          </a-table>
          <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
            <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import pagination from '@/mixins/pagination'
import terminaltypes from '@/json/terminaltypes'
import {getMaintenanceKeepStatistic, exportMaintenanceKeepExcel, getMaintenanceStatistic} from 'A/bigdata.js'
import { titleMap3 } from './pageConfig'
import {getMaintenanceMainStatistic} from "A/bigdata";
export default {
  mixins: [pagination],
  props: {
    interfaceName: {
      default: ''
    },
    querytype: {
      default: ''
    }
  },
  data() {
    return {
      ec: null,
      terminaltypes,
      showAdvanced: false,
      queryParams: {
        queryvalue: '',
      },
      arealevel:'',
      allDatas: [],
      tableKey: '',
      tableColumns: [],
      tableData: [],
      tableLoading: false,
      counts:'',
    }
  },
  watch: {
    querytype(val) {
      if(val) {
        this.initTableData();
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.ec = echarts.init(this.$refs.echarts);
      this.initTableData();
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    initTableData() {
      this.tableColumns = [
        {
          title: titleMap3[this.querytype]||'行政区域',
          dataIndex: 'keyname',
          key: 'keyname',
          ellipsis: true,
        },
        {
          title: '电梯数',
          dataIndex: 'liftnum',
          key: 'liftnum',
          ellipsis: true,
        },
        {
          title: '维修总数',
          dataIndex: 'num',
          key: 'num',
          ellipsis: true,
        },
        {
          title: '正常维修数量',
          dataIndex: 'normalnum',
          key: 'normalnum',
          ellipsis: true,
        },
        {
          title: '异常维修数量',
          dataIndex: 'exceptnum',
          key: 'exceptnum',
          ellipsis: true,
        },
      ],
      this.tableLoading = true;
      let params = {
        arealevel:this.arealevel,
        querytype: this.querytype,
        ...this.queryParams,
      };
      getMaintenanceKeepStatistic(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.allDatas = res.item;
          this.pagination.total = res.count;
          this.getTableData();
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableData = this.allDatas.slice((this.currentPage-1)*this.pagination.pageSize, this.currentPage*this.pagination.pageSize)
      this.pkgEchartData();
    },
    pkgEchartData() {
      let xdata = [],series = [];
      let xkey = 'keyname', ykeys = ['liftnum', 'num', 'normalnum', 'exceptnum'], legend = ['电梯数', '维修总数', '正常维修数量', '异常维修数量'], legendMap = {
        liftnum: '电梯数',
        num: '维修总数',
        normalnum: '正常维修数量',
        exceptnum: '异常维修数量',
      };
      this.tableData.forEach(item => {
        xdata.push(item[xkey]);
      });
      ykeys.forEach(ykey => {
        let ydata = [];
        this.tableData.forEach(item => {
          ydata.push(item[ykey]);
        })
        series.push({
          name: legendMap[ykey],
          data: ydata,
          type: 'bar'
        });
      })
      let xLabelWidth = 0;
      try {
        let echartsWidth = parseInt(getComputedStyle(this.$refs.echarts).width);
        let xAxisWidth = echartsWidth * 0.8 - 50;
        xLabelWidth = xAxisWidth/xdata.length;
      }catch(e) {}
      let option = {
        legend: {
          data: legend
        },
        // grid: {
        //   bottom: 90  
        // },
        xAxis: {
          type: 'category',
          axisLabel: xLabelWidth?{
            interval: 0,
            // rotate: 23,
            width: xLabelWidth,
            overflow: 'truncate',
          }:{},
          data: xdata
        },
        yAxis: {
          type: 'value'
        },
        series: series,
        tooltip: {
          show: true,
          trigger: 'axis'
        }
      };
      this.ec.setOption(option);
    },
    exportData() {
      let params = {
        arealevel:this.arealevel,
        querytype: this.querytype,
        ...this.queryParams,
      };
      this.showLoading();
      exportMaintenanceKeepExcel(params).then(()=>{
        this.hideLoading();
      }).catch(()=>{
        this.hideLoading();
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.statistic-container {
  height: calc(100vh - 120px);
  overflow-y: auto;
  // border: solid 1px red;
  .echars-container {
    height: 400px;
    background-color: white;
    margin-top: 10px;
    border-radius: 2px;
  }
}
</style>