import { render, staticRenderFns } from "./StatisticPage1.vue?vue&type=template&id=eaf7d10c&scoped=true&"
import script from "./StatisticPage1.vue?vue&type=script&lang=js&"
export * from "./StatisticPage1.vue?vue&type=script&lang=js&"
import style0 from "./StatisticPage1.vue?vue&type=style&index=0&id=eaf7d10c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eaf7d10c",
  null
  
)

export default component.exports